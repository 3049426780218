@font-face {
  font-display: swap;
  font-family: 'iCielBrandonText';
  font-style: normal;
  font-weight: 700;
  src: url('~assets/fonts/iCielBrandonText-Bold.woff2') format('woff2'),
    url('~assets/fonts/iCielBrandonText-Bold.woff') format('woff'), url('~assets/fonts/iCielBrandonText-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'SVN-Androgyne';
  font-style: normal;
  font-weight: normal;
  src: url('~assets/fonts/SVN-Androgyne.woff2') format('woff2'),
    url('~assets/fonts/SVN-Androgyne.woff') format('woff'), url('~assets/fonts/SVN-Androgyne.ttf') format('truetype');
}
