// Color
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);

$silver-sand: #c4c4c4;
$jazzberry-jam: #9e1f63;
$cardinal: #be1e2d;
$blizzard-blue: #b2dbf1;
$rich-electric-blue: #0f8dce;
$taupeGray: #8c8c8c;
$antiflashWhite: #f1f1f2;
$metallic: #dba846;
$yale-blue: #1c4584;
$light-gray: #d0d2d3;

// ----
$mediumSeaGreen: #3BA558;
$darkSpringGreen: #116B38;
$platinum: #E7E8E8;
$dimgray: #6d6e70;

// Overlay
$white-overlay02: rgba($white, 0.2);
$white-overlay04: rgba($white, 0.4);
$white-overlay08: rgba($white, 0.8);
$black-overlay02: rgba($black, 0.2);
$black-overlay04: rgba($black, 0.4);
$black-overlay07: rgba($black, 0.7);
$rich-electric-blue05: rgba($rich-electric-blue, 0.5);
