.a-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;

  img {
    display: none;
  }

  &-inherit {
    background-size: inherit;
  }

  &-initial {
    background-size: initial;
  }
  
  &-contain {
    background-size: contain;
  }


  &-logo {
    @include aspect-ratio(345, 95);
  }

  &-banner {
    @include aspect-ratio(375, 488);
  }

  &-banner-2 {
    @include aspect-ratio(375, 420);
  }

  &-abbott {
    @include aspect-ratio(174, 80);
  }
}
