.a-input {
  $root: &;

  &_input {
    // background: linear-gradient(90deg, $rich-electric-blue 0%, $rich-electric-blue05 99.79%);
    background-color: $platinum;
    border: 1px solid $mediumSeaGreen;
    border-radius: rem(8);
    box-shadow: inset 0 0 rem(4) $black-overlay07;
    box-sizing: border-box;
    color: $dimgray;
    font-family: $iCielBrandonText;
    font-weight: bold;
    padding: rem(10) rem(16);
    width: 100%;
    @include font-base(14, 20);
  
    &::placeholder {
      color: $light-gray;
      font-weight: bold;
      @include font-base(14, 20);
    }

    &::-webkit-input-placeholder {
      color: $light-gray;
      font-weight: bold;
      @include font-base(14, 20);
    }

    &::-ms-input-placeholder {
      color: $light-gray;
      font-weight: bold;
      @include font-base(14, 20);
    }

    &:focus {
      outline: none;
    }
  
    &:disabled {
      color: $white-overlay02;
    }
  }

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input::-webkit-contacts-auto-fill-button {
  margin: 0;
  width: 0;
  background-color: transparent;
}