@mixin iconImage($width: 24, $height: 24, $url: '') {
  background-image: url($url);
  height: rem($height);
  width: rem($width);
}

.a-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;

  &-loading {
    @include iconImage(24, 24, '~assets/icons/loading.svg');
  }

  &-loadingBlack {
    @include iconImage(24, 24, '~assets/icons/loading_black.svg');
  }

  &-saveAlt {
    @include iconImage(24, 24, '~assets/icons/save_alt.svg');
  }

  &-share {
    @include iconImage(24, 24, '~assets/icons/share.svg');
  }

  &-cameraAlt {
    @include iconImage(24, 24, '~assets/icons/camera_alt.svg');
  }

  &-giftCard {
    @include iconImage(24, 24, '~assets/icons/gift_card.svg');
  }

  &-arrowDownWhite {
    @include iconImage(24, 24, '~assets/icons/arrow_down_white.svg');
  }

  &-rotate {
    @include iconImage(24, 24, '~assets/icons/rotate.svg');
  }
}
