@mixin text-overflow ($number: 2) {
  display: box;
  -webkit-line-clamp: ($number);
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin aspect-ratio($width: 1, $height: 1) {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: ($height / $width) * 100%;
  }
}

@mixin transition($value) {
  transition: $value;
}

@mixin scroll-bars(
  $size: 12,
  $thumb-color: $silver-sand,
  $track-color: $white,
  $border-radius-thumb: 5,
  $border-thumb: 2
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    height: rem($size);
    margin-right: rem(10);
    width: rem($size);
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border: rem($border-thumb) solid $track-color;
    border-radius: rem($border-radius-thumb);
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  // For Internet Explorer
  
  scrollbar-face-color: $thumb-color;
  scrollbar-track-color: $track-color;
}

@mixin font-base($font-size: 16, $line-height: 24) {
  font-size: rem($font-size);
  line-height: rem($line-height);
}

@mixin style-bg-color() {
  &-bg-white {
    background-color: $white;
  }

  &-bg-black {
    background-color: $black;
  }

  &-bg-antiflashWhite {
    background-color: $antiflashWhite;
  }
}

@mixin style-font-weight() {
  &-100 {
    font-weight: 100;
  }

  &-200 {
    font-weight: 200;
  }

  &-300 {
    font-weight: 300;
  }

  &-400 {
    font-weight: normal;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: bold;
  }

  &-800 {
    font-weight: 800;
  }

  &-900 {
    font-weight: 900;
  }
}

@mixin style-general() {
  &-uppercase {
    text-transform: uppercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-underline {
    text-decoration: underline;
  }

  &-italic {
    font-style: italic;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }
}

@mixin style-font-size() {
  &-t0 {
    @include font-base(40, 48);
  }

  &-t1 {
    @include font-base(32, 40);
  }

  &-t2 {
    @include font-base(20, 26);
  }

  &-t3 {
    @include font-base(16, 24);
  }

  &-p1 {
    @include font-base(16, 24);
  }

  &-p2 {
    @include font-base(14, 20);
  }

  &-p3 {
    @include font-base(12, 18);
  }

  &-c1 {
    @include font-base(11, 16);
  }

  &-c2 {
    @include font-base(12, 14);
  }

  &-c3 {
    @include font-base(6, 12);
  }
}

@mixin reset-button {
  appearance: none;
  background-color: none;
  border: 0;
  outline: none;
}

@mixin list-style-none {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
  align-items: $align-item;
  display: flex;
  justify-content: $justify-content;
}

$colors-design: (
  'white': $white,
  'black': $black,
  'jazzberry-jam': $jazzberry-jam,
  'cardinal': $cardinal,
  'blizzard-blue': $blizzard-blue,
  'rich-electric-blue': $rich-electric-blue,
  'taupeGray': $taupeGray,
  'metallic': $metallic,
  'yale-blue': $yale-blue,
  'light-gray': $light-gray,
  'silver-sand': $silver-sand,
  'mediumSeaGreen': $mediumSeaGreen,
);

@mixin style-color {
  @each $name, $color in $colors-design {
    &-#{$name} {
      color: $color;
    }
  }
}

@mixin fullscreen {
  height: 100vh;
  max-height: fill-available;
  overflow: hidden;
  position: relative;
  width: 100vw;
}


@mixin bg-custom {
  background-image: url('~assets/images/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
