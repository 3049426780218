.a-button {
  $root: &;
  background: linear-gradient(116.2deg, #d0932b 7.28%, #dba846 10.22%, #e6bd64 13.46%, #efce7c 16.77%, #f5db8c 20.14%, #f9e396 23.6%, #fbe59a 27.28%, #f3d687 32.14%, #e1b356 42.17%, #d0932b 51.44%, #ddac4b 57.09%, #ecca76 64.16%, #f7dd90 69.56%, #fbe59a 72.62%, #d0932b 93.03%);
  border: 0;
  border-radius: rem(999);
  box-shadow: 0 4px 4px rgba($color: $black, $alpha: 0.25);
  cursor: pointer;
  height: rem(72);
  outline: none;
  padding: rem(4);
  width: 100%;

  &:disabled {
    opacity: 0.5;
  }

  &_wrapper {
    background: linear-gradient(359.42deg, #3BA558 0.44%, #116B38 99.44%);
    border-radius: rem(999);
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      background: linear-gradient(180deg, #9fb8c7 1.73%, #010101 100.84%);
      content: '';
      height: 50%;
      left: 0;
      mix-blend-mode: screen;
      opacity: 0.8;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.a-textbutton {
  $root: &;
  @include reset-button();
  background-color: transparent;
  text-decoration: underline;

  &:disabled {
    opacity: 0.5;
  }
}
