.a-text {
  font-family: $iCielBrandonText;

  // TODO: remove later
  &-gold {
    background: linear-gradient(116.2deg, #d0932b 7.28%, #dba846 10.22%, #e6bd64 13.46%, #efce7c 16.77%, #f5db8c 20.14%, #f9e396 23.6%, #fbe59a 27.28%, #f3d687 32.14%, #e1b356 42.17%, #d0932b 51.44%, #ddac4b 57.09%, #ecca76 64.16%, #f7dd90 69.56%, #fbe59a 72.62%, #d0932b 93.03%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-green {
    background: linear-gradient(359.42deg, #3BA558 0.44%, #116B38 99.44%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-androgyne {
    font-family: $androgyne;
  }

  @include style-font-size();
  @include style-color();
  @include style-font-weight();
  @include style-general();
}
