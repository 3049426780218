.p-preview {
  height: 100vh;
  max-height: fill-available;
  overflow: hidden;
  position: relative;
  width: 100vw;
  
  video.preview {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  video.frame {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  .btn-unmute {
    animation: blinker 1s linear infinite;
    background: none;
    border: none;
    filter: drop-shadow(0 0 12px black);
    left: 20px;
    position: absolute;
    top: 20px;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
