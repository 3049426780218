// fonts
$iCielBrandonText: 'iCielBrandonText';
$androgyne: 'androgyne';

// transition
$transition: 0.25s;
$transition-long: 0.4s;

// z-index
$z-header: 100;
$z-modal: 101;
$z-rose: 99;
