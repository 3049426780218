.p-staff {
  height: 100vh;
  max-height: fill-available;
  overflow: hidden;
  @include bg-custom();

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .logo {
    margin-left: auto;
    margin-right: auto;
    padding-top: rem(16);
    width: rem(237);
    @include small-mobile-up {
      margin-top: rem(120);
    }
  }


  .description {
    margin-top: rem(40);

  }

  .form {
    .item {
      display: flex;
      justify-content: center;
      margin-bottom: rem(40);
      margin-top: rem(60);

      .label,
      .value {
        padding-left: rem(12);
        padding-right: rem(12);
      }

      .label {
        flex: 0 0 rem(100);
        margin-top: rem(10);
        max-width: rem(100);
      }

      .value {
        max-width: rem(200);
        width: 100%;

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }

    .submit {
      margin-left: auto;
      margin-right: auto;
      margin-top: rem(40);
      max-width: rem(171);
    }
  }
  
 
  .bottom {
    margin-top: auto;
    padding-bottom: rem(16);
    padding-left: rem(16);
    padding-right: rem(16);
    @include font-base (10, 16);

    .abbott {
      width: rem(75);
    }
  }
}
