@mixin ic-plus {
  height: rem(36);
  position: relative;
  width: rem(36);

  &::before,
  &::after {
    background: $white;
    content: '';
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    height: 1px;
    width: 100%;
  }

  &::after {
    height: 100%;
    width: 1px;
  }
}

.p-scan {
  padding: rem(16);
  position: relative;
  @include bg-custom();

  .logo {
    margin-left: auto;
    margin-right: auto;
    width: rem(237);
    @include small-mobile-up {
      margin-top: rem(120);
    }
  }

  .description {
    margin-top: rem(16);
    text-shadow: 1px 1px $black;
  }

  .box {
    @include aspect-ratio(1, 1);
    background-image: url('~assets/icons/box.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(16);

    &_camera {
      align-items: center;
      border-radius: rem(8);
      bottom: rem(7);
      display: flex;
      justify-content: center;
      left: rem(7);
      overflow: hidden;
      position: absolute;
      right: rem(7);
      top: rem(7);

      video.camera {
        border-radius: rem(8);
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .a-icon {
        position: relative;
      }
    }

    &_loading {
      align-items: center;
      background-color: $white-overlay04;

      border-radius: rem(8);
      display: flex;
      height: 100%;
      justify-content: center;
      overflow: hidden;
      position: absolute;
      width: 100%;

      .plus {
        @include ic-plus();
      }
    }
  }


  .bottom {
    @include font-base (10, 16);
    margin-top: rem(48);

    .abbott {
      width: rem(75);
    }
  }
}
