.o-modal {
  $root: &;
  left: rem(8);
  outline: 0;
  position: absolute;
  right: rem(8);
  top: 50%;
  transform: translateY(-50%);

  &_container {
    animation: roadRunnerIn 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    background: linear-gradient(90deg, $rich-electric-blue 0%, $rich-electric-blue 99.79%);
    border-radius: rem(16);
    box-shadow: 4px 4px 4px $black-overlay04;
    margin-left: auto;
    margin-right: auto;
    max-width: rem(480);
    overflow: auto;
    padding: rem(20) rem(16);
    position: relative;
  }

  &_close {
    text-align: right;

    button {
      background-color: transparent;
      border: 2px solid $mediumSeaGreen;
      border-radius: 50%;
      height: rem(28);
      position: relative;
      width: rem(28);

      &::before,
      &::after {
        background-color: $mediumSeaGreen;
        content: '';
        height: rem(2);
        position: absolute;
        width: rem(18);
      }

      &::before {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(135deg);
      }
    }
  }
}

.o-modal-portal {
  &-open {
    opacity: 1;
  }
  /* Add modifiers */
  &-bg-antiflashWhite {
    .o-modal_container {
      background: $antiflashWhite;
    }
  }

  &-bg-white {
    .o-modal_container {
      background: $white;
    }
  }

  &-icon-gold {
    .o-modal_close {
      button {
        border-color: $yale-blue;

        &::after,
        &::before {
          background-color: $yale-blue;
        }
      }
    }
  }
}

.reactmodal-body-open,
.reactmodal-html-open {
  max-height: 100vh;
  overflow: hidden;
}


@keyframes roadRunnerIn {
  0% {
    transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
  }

  70% {
    transform: translateX(30px) skewX(0deg) scaleX(0.9);
  }

  100% {
    transform: translateX(0) skewX(0deg) scaleX(1);
  }
}
