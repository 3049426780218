.p-complete {
  padding-bottom: rem(16);
  @include bg-custom();

  .title {
    margin-top: rem(16);
    text-shadow: 1px 1px 2px $black;
  }

  .sub-banner {
    margin-top: rem(-12);
  }

  .subtext {
    margin-top: rem(6);
  }

  .bottom {
    margin-top: rem(32);
    padding-left: rem(16);
    padding-right: rem(16);
    @include font-base (10, 16);

    .abbott {
      width: rem(75);
    }
  }
}
