.p-home {
  padding-bottom: rem(16);
  height: 100vh;
  max-height: fill-available;
  overflow: hidden;
  @include bg-custom();

  .controls {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(28);
    max-width: rem(236);
    width: 100%;

    .a-button {
      height: rem(64);
    }
  }

  .sub-banner {
    margin-top: rem(-12);
  }

  .preview {
    margin-top: rem(14);

  }

  .rule {
    margin-top: rem(24);
    text-align: center;
  }

  .bottom {
    margin-top: rem(24);
    padding-left: rem(16);
    padding-right: rem(16);
    @include font-base (10, 16);

    .abbott {
      width: rem(75);
    }
  }
}

.modal-rule {
  max-height: rem(480);
  overflow-y: scroll;
  padding-bottom: rem(16);
  @include scroll-bars(4, $silver-sand, transparent, 5, 0);

  .panel {
    background: $antiflashWhite;
    background-clip: padding-box;
    border: solid 2px transparent;
    border-radius: rem(8);
    box-sizing: border-box;
    margin-top: rem(12);
    padding: rem(12) rem(24);
    position: relative;

    &::before {
      background: linear-gradient(116.2deg, #d0932b 7.28%, #dba846 10.22%, #e6bd64 13.46%, #efce7c 16.77%, #f5db8c 20.14%, #f9e396 23.6%, #fbe59a 27.28%, #f3d687 32.14%, #e1b356 42.17%, #d0932b 51.44%, #ddac4b 57.09%, #ecca76 64.16%, #f7dd90 69.56%, #fbe59a 72.62%, #d0932b 93.03%);
      border-radius: inherit;
      bottom: 0;
      content: '';
      left: 0;
      margin: rem(-2);
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  .rule {
    line-height: rem(14);
    margin-top: rem(16);

    &-description {
      line-height: rem(14);

      .link {
        color: $rich-electric-blue;
        text-decoration: underline;
      }

      &_custom {
        li {
          list-style-type: '-';
          margin-left: rem(4);
          padding-left: rem(8);
  
          &::marker {
            color: $yale-blue;
          }
        }
      }

      &_sub {
        &:not(:first-of-type) {
          margin-top: rem(8);
        }

        > span {
          &:first-of-type {
            margin-right: rem(14);
          }
        }
      }
    }
  }
}
