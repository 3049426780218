.a-checkbox {
  position: relative;
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: rem(20);
    height: rem(20);
    margin-right: rem(15);
    visibility: hidden;

    &:checked {
      ~label::after {
        visibility: visible;
      }
    }
  }

  label {
    margin-bottom: 0;
    cursor: pointer;
    @include font-base(18, 24);
    color: $white;
    font-family: $iCielBrandonText;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: rem(4);
      left: 0;
      width: rem(24);
      height: rem(24);
      border: rem(1) solid $white;
      background-color: $white;
      transition: 0.25s;
    }

    &::after {
      content: '';
      position: absolute;
      top: rem(1);
      left: rem(11);
      width: rem(8);
      height: rem(20);
      border: solid $yale-blue;
      border-width: 0 rem(4) rem(4) 0;
      transform: rotate(45deg);
      visibility: hidden;
      transition: 0.1s;
    }
  }

  .border {
    position: absolute;
    width: rem(28);
    height: rem(28);
    top: rem(3);
    left: rem(-1);
    background: linear-gradient(116.2deg, #d0932b 7.28%, #dba846 10.22%, #e6bd64 13.46%, #efce7c 16.77%, #f5db8c 20.14%, #f9e396 23.6%, #fbe59a 27.28%, #f3d687 32.14%, #e1b356 42.17%, #d0932b 51.44%, #ddac4b 57.09%, #ecca76 64.16%, #f7dd90 69.56%, #fbe59a 72.62%, #d0932b 93.03%);
  }
}